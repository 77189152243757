import React, {useEffect} from "react";
import {
  Route,
  Routes,
} from "react-router-dom";
import './App.scss';
import {JobSearchPage, Home} from './components';
import util from "./helpers/util";


function App() {

  useEffect(() => {
    util.initOT(process.env.REACT_APP_OT_DOMAIN)
    util.initGTM(process.env.REACT_APP_GTM_CONTAINER)
  }, [])

  return (
    <div className="App">


      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:companyPage" element={<Home />}/>
          <Route path="/jobs/:jobId/:jobTitle?" element={<JobSearchPage />} />
          <Route path="/:companyPage/:jobs/:jobId/:jobTitle?" element={<JobSearchPage />} />

      </Routes>
    </div>
  );
}

export default App;
