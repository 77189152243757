import {elemis, erborian, loccitane, loccitane_group} from "../img/logos";
import { loccitane_banner, erborian_banner, elemis_banner, groupe_loccitane_banner } from "../img/banners";
import { elemisFooter, erborianFooter, erborianFooter2, globalFooter, loccitaneFooter, loccitaneFooter2, loccitaneFooter3 } from "../img/footer";

export const brandNav = {
    "elemis": {
        "name": "Elemis",
        "image": elemis,
        "display": true,
        "urlParam": "elemis",
        "banner": elemis_banner,
        "id": "elemis",
        "video_url": "https://player.vimeo.com/video/824163875?h=61faaa40d9",
        "video_title": "Elemis Brand Video",
        "text":"<p>ELEMIS is a skin wellness brand with an aromatherapist’s soul, an artist’s spirit, and a scientist’s commitment to results. An innovative and global British skincare brand with over 30 years of expertise and available in over 45 countries, we believe in ‘truth in beauty’.<br/><br/>We are passionate about taking care of your skin. Our focus is delivering proven results with feel-good skincare products combined with cutting-edge patented technology. Our award-winning skincare portfolio includes face and body skincare products with revolutionary formulas, spa treatments, supplements and more.<br/>We know your skin because we’ve seen your skin: every year we work with many millions of clients across the globe - in our spas, in our stores, and via virtual consultations. Our extensive experience in facial services means we have first-hand knowledge of our clients complexions and we are experienced at tailoring skin concern solutions for every individual.<br/><br/>Nature powers our products: by sourcing natural extracts that catalyze each other and harnessing the power of nature’s finest active ingredients, we can deliver results that you can see and feel, both inside and out.<br/><br/>We are looking for individuals who share the passion, drive and commitment that has made ELEMIS what it is today. Learn more about ELEMIS at <a target='_blank' href='https://us.elemis.com'>US.Elemis.com</a> or find us on your favorite social sites @elemis.<br/><br/>Thank you for visiting our North America Career Page. Please review our open positions and apply to the positions that match your qualifications.</p>"

    },
    "erborian": {
        "name": "Erborian",
        "image": erborian,
        "display": true,
        "urlParam": "erborian",
        "banner": erborian_banner,
        "id": "erborian",
        "video_url": "https://player.vimeo.com/video/824167759?h=2f8b34410b",
        "video_title": "Erborian Brand Video",
        "text": "<p>Empowering you to feel more confident in your bare skin, at ERBORIAN we believe that life would be better if staying true to yourself was enough to feel good. Founded by a French skincare guru and a Korean scientist, our brand fuses together renowned Korean skincare technology and French skincare expertise. We offer effortless, innovative products with a skin-first approach. Our hybrid products - between makeup and traditional skincare – work to put you and your skin first, empowering you to do the most for your complexion with the least number of steps.<br/><br/>Learn more about ERBORIAN - visit <a target='_blank' href='https://www.erborian.com'>Erborian.com</a> or find us on your favorite social sites @erborian_usa.<br/><br/>Thank you for visiting our North America Career Page. Please review our open positions and apply to the positions that match your qualifications.</p>"
    },
    "loccitane": {
        "name": "L'Occitane",
        "image": loccitane,
        "display": true,
        "urlParam": "loccitane",
        "banner": loccitane_banner,
        "id": "loccitane",
        "video_url": "https://player.vimeo.com/video/824136059?h=8c45ca29f3",
        "video_title": "L&#39;Occitane Brand Video",
        "text": "<p>L’OCCITANE [LOX-EE-THAN] is an iconic beauty brand from the South of France, beloved for offering luxurious beauty products inspired by Provencal living and the finest quality natural ingredients in partnership with local farming. We take pride in our commitment to supporting producers, respecting biodiversity, reducing waste and empowering women. We strive to create an environment where everyone can be their true selves through our core values of: authenticity, entrepreneurship, team spirit and leading by example.<br/><br/>Learn more about L’OCCITANE - visit <a target='_blank' href='https://www.loccitane.com'>Loccitane.com</a> or find us on your favorite social sites @loccitane.<br/><br/>Thank you for visiting our North America Career Page. Please review our open positions and apply to the positions that match your qualifications.</p>"
    }, 
    "groupe-loccitane" : {
        "name": "Groupe L'Occitane",
        "image": loccitane_group,
        "display": false,
        "urlParam": "",
        "banner": groupe_loccitane_banner,
        "id": "groupe-loccitane",
        "text": "<p>Our Mission: With empowerment we positively impact people and regenerate nature.</p> <p>The L'OCCITANE Group is comprised of premium beauty brands that champion organic and natural ingredients. With our nature-positive vision and entrepreneurial ethos, we are committed to investing in communities, biodiversity, reducing waste and to finding sustainable solutions to create a better and healthier planet.</p> <p>Thank you for visiting our North America Career Page. Please review our open positions and apply to the positions that match your qualifications.</p>",
        "video_url": "https://www.youtube.com/embed/2k2BXd5-xE0",
        "video_title": "Groupe L&#39;Occitane Brand Video"
    }
}

export const brandContent = {
    'elemis': {
        "name": "Elemis",
        "color": "#28255A",
        "image": elemis,
        "id": "elemis",
        "textColor": "#fff",
        "url": "https://www.elemis.com/",
        "footerText": "",
        "title": "Elemis Careers- North America Job Opportunities",
        "footerImages": [{src: globalFooter, alt: "B-Corp Certified Badge"}, {src: elemisFooter, alt: "Living Wage Certified Badge"}]
    },
    'erborian': {
        "name": "Erborian",
        "color": "#D0333A",
        "image": erborian,
        "textColor": "#fff",
        "id": "erborian",
        "url": "https://usa.erborian.com/",
        "title": "Erborian Careers - North America Job Opportunities",
        "footerText": "",
        "footerImages": [{src: globalFooter, alt: "B-Corp Certified Badge"},  {src: erborianFooter, alt: "Great Place To Work Certified Badge from August 2022 to July 2023. Canada"}, {src: erborianFooter2, alt: "Great Place To Work Certified Badge from August 2022 to July 2023. USA"}]

    },
    'loccitane': {
        "name": "L'Occitane",
        "color": "#FFCB00",
        "image": loccitane,
        "id": "loccitane",
        "textColor": "#000",
        "url": "https://www.loccitane.com",
        "title": "L'Occitane Careers - North America Job Opportunities",
        "footerText": "",
        "footerImages": [{src: globalFooter, alt: "B-Corp Certified Badge"}, {src: loccitaneFooter, alt: "Great Place To Work Certified Badge from August 2022 to July 2023. Canada"},  {src: loccitaneFooter2, alt: "Great Place To Work Certified Badge from August 2022 to July 2023. USA"}, {src: loccitaneFooter3, alt: "Best Workplace in Retail & Hospitality. Great Place To Work Certified Badge. 2023. Canada"}]

    }, 
    'groupe-loccitane': {
        "name": "Groupe L'Occitane",
        "color": "#1C1F2A",
        "image": loccitane_group,
        "textColor": "#fff",
        "id": "groupe-loccitane",
        "url": "https://group.loccitane.com/",
        "footerText": "Groupe L’Occitane North America",
        "title": "Groupe L'Occitane Careers - North America Job Opportunities",
        "footerImages": [{src: globalFooter, alt: "B-Corp Certified Badge"}]
    }
}

